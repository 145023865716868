import {
  FaCalendar,
  FaCheck,
  FaCog,
  FaDollarSign,
  FaHome,
  FaList,
  FaMoneyBill,
  FaSign,
  FaSignature,
  FaUser,
  FaUsers,
} from "react-icons/fa";

export const PATH_HOME = "/dashboard";

const menu = [
  {
    id: "home",
    title: "Home",
    icon: <FaHome />,
    href: PATH_HOME,
  },
  // {
  //   id: 'consultas',
  //   title: 'Consultas',
  //   icon: <FaCheck />,
  //   href: '/dashboard/consultas'
  // },
  {
    id: "pacientes",
    title: "Meus Pacientes",
    icon: <FaUsers />,
    href: "/dashboard/pacientes",
    submenu: [
      {
        id: "novo-paciente",
        title: "Novo Paciente",
        href: "/dashboard/pacientes/novo",
      },
      {
        id: "detalhe-paciente",
        title: "Detalhes do Paciente",
        href: "/dashboard/pacientes/detalhes",
      },
    ],
  },
  // {
  //   id: 'agenda',
  //   title: 'Agenda',
  //   icon: <FaCalendar />,
  //   href: '/dashboard/agenda'
  // },
  // {
  //   id: 'financeiro',
  //   title: 'Financeiro',
  //   icon: <FaDollarSign />,
  //   href: '/dashboard/financeiro'
  // },
  // {
  //   id: "assinatura",
  //   title: "Assinatura",
  //   icon: <FaSignature />,
  //   href: "/dashboard/assinatura",
  // },
  {
    id: "configuracoes",
    title: "Configurações",
    icon: <FaCog />,
    href: "/dashboard/configuracoes/meu-cadastro",
    isPathParam: true,
  },
];

export default menu;
