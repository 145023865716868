import axios from "axios";
import Cookies from "js-cookie";
import { logOut } from "@/services/auth";
import { getCurrentEstablishment } from "./establishments";

export const httpService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpService.interceptors.request.use(
  (config) => {
    const token = Cookies.get(process.env.REACT_APP_AUTH_TOKEN);
    const currentEstablishment = getCurrentEstablishment();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (currentEstablishment) {
      config.headers["establishment-id"] = currentEstablishment;
    }

    if (config.params && config.params["pagination[page]"]) {
      config.params["pagination[pageSize]"] =
        process.env.REACT_APP_PAGINATION_PAGE_SIZE;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      logOut();
      window.location.reload();
      return {};
    }
    return Promise.reject(error);
  }
);

export const eventSource = (path) =>
  new EventSource(`${process.env.REACT_APP_BASE_URL}/${path}`);
