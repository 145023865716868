import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
  width: calc(100% - 3.2rem);
  margin: 1.6rem auto 0;

  position: relative;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 0.9rem;
    color: ${theme.colors.gray400};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
  `}
`

export const Establishment = styled.div`
  ${({ theme }) => css`
    padding: 1rem 1.6rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${theme.colors.white};
    transition: 0.3s;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${theme.colors.white};
    background-color: ${theme.colors.dark};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.darkeen.hover};
    }
  `}
`

export const Overlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    background-color: ${rgba(theme.colors.black, 0.9)};

    z-index: 1;
  `}
`

export const EstablishmentContent = styled.div`
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  min-width: 25rem;
  z-index: 2;
`

export const Establishments = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

`

export const EstablishmentName = styled.h3`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.white};
    font-weight: 600;
  `}
`
