import { lazy } from "react";
    
    const publichome = lazy(() => import('@/pages/public/home/index.page.js'));
const dashboardesqueciMinhaSenha = lazy(() => import('@/pages/public/esqueciMinhaSenha/index.page.js'));
const dashboardpacientes = lazy(() => import('@/pages/dashboard/pacientes/index.page.js'));
const dashboardpacientesNovo = lazy(() => import('@/pages/dashboard/pacientes/novo/index.page.js'));
const dashboardpacientesDetalhes = lazy(() => import('@/pages/dashboard/pacientes/detalhes/index.page.js'));
const dashboardlogin = lazy(() => import('@/pages/dashboard/login/index.page.js'));
const dashboardhome = lazy(() => import('@/pages/dashboard/home/index.page.js'));
const dashboardfinanceiro = lazy(() => import('@/pages/dashboard/financeiro/index.page.js'));
const dashboardconsultas = lazy(() => import('@/pages/dashboard/consultas/index.page.js'));
const dashboardconfiguracoes = lazy(() => import('@/pages/dashboard/configuracoes/index.page.js'));
const dashboardcadastro = lazy(() => import('@/pages/dashboard/cadastro/index.page.js'));
const dashboardcadastrarNovaSenha = lazy(() => import('@/pages/dashboard/cadastrarNovaSenha/index.page.js'));
const dashboardassinatura = lazy(() => import('@/pages/dashboard/assinatura/index.page.js'));
const dashboardagenda = lazy(() => import('@/pages/dashboard/agenda/index.page.js'));
const customerlogin = lazy(() => import('@/pages/customer/login/index.page.js'));
const customerexames = lazy(() => import('@/pages/customer/exames/index.page.js'));
const examesDetalhes = lazy(() => import('@/pages/customer/exames/detalhes/index.page.js'));
const customerdocumentos = lazy(() => import('@/pages/customer/documentos/index.page.js'));
const dashboardanamnese = lazy(() => import('@/pages/customer/anamnese/index.page.js'));
    
    export const routes = [
        { path: "/", page: publichome, isPublic: false, template: "public", title: "Home", isForm: "false" },
{ path: "/esqueci-minha-senha", page: dashboardesqueciMinhaSenha, isPublic: true, template: "blank", title: "Esqueci minha senha", isForm: "true" },
{ path: "/dashboard/pacientes", page: dashboardpacientes, isPublic: false, template: "default", title: "Pacientes", isForm: "false" },
{ path: "/dashboard/pacientes/novo", page: dashboardpacientesNovo, isPublic: false, template: "default", title: "Novo Paciente", isForm: "true" },
{ path: "/dashboard/pacientes/detalhes/:id", page: dashboardpacientesDetalhes, isPublic: false, template: "default", title: "Detalhe do Paciente", isForm: "true" },
{ path: "/dashboard/login", page: dashboardlogin, isPublic: true, template: "blank", title: "Entrar", isForm: "true" },
{ path: "/dashboard", page: dashboardhome, isPublic: false, template: "default", title: "Home", isForm: "false" },
{ path: "/dashboard/financeiro", page: dashboardfinanceiro, isPublic: false, template: "default", title: "Financeiro", isForm: "false" },
{ path: "/dashboard/consultas", page: dashboardconsultas, isPublic: false, template: "default", title: "Consultas", isForm: "false" },
{ path: "/dashboard/configuracoes/:tab", page: dashboardconfiguracoes, isPublic: false, template: "default", title: "Configurações", isForm: "true" },
{ path: "/dashboard/cadastro", page: dashboardcadastro, isPublic: true, template: "blank", title: "Cadastrar-se", isForm: "true" },
{ path: "/dashboard/cadastrar-nova-senha/:hash", page: dashboardcadastrarNovaSenha, isPublic: true, template: "blank", title: "Cadastrar nova senha", isForm: "true" },
{ path: "/dashboard/assinatura", page: dashboardassinatura, isPublic: false, template: "default", title: "Assinatura", isForm: "false" },
{ path: "/dashboard/agenda", page: dashboardagenda, isPublic: false, template: "default", title: "Agenda", isForm: "false" },
{ path: "/paciente/login", page: customerlogin, isPublic: true, template: "blank", title: "Entrar", isForm: "true" },
{ path: "/exames/:hash", page: customerexames, isPublic: false, template: "blank", title: "Exames", isForm: "true" },
{ path: "/exames/:hash/detalhes", page: examesDetalhes, isPublic: true, template: "blank", title: "Detalhes Pedido de Exame", isForm: "não" },
{ path: "/documentos/:hash", page: customerdocumentos, isPublic: false, template: "blank", title: "Documentos", isForm: "true" },
{ path: "/anamnese/:hash", page: dashboardanamnese, isPublic: true, template: "blank", title: "Anamnese", isForm: "true" }
    ];
    