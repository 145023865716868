import { Helmet } from 'react-helmet';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { Suspense } from 'react';

import templateBlank from '@/templates/Blank'
import templateDefault from '@/templates/Default'
import templatePublic from '@/templates/Public'
import { routes } from './routes';
import PageLoading from './components/Loaders/PageLoading';

const templates = {
  blank: templateBlank,
  default: templateDefault,
  public: templatePublic
}

function AppContent() {

  return (
    <>
      <PageLoading />
      <Routes>
        {routes.map((route, index) => {
          const Template = templates[route.template];
          const Page = route.page;

          const title = route.title;

          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Template isPublic={route.isPublic} form={route.isForm === 'true'}>
                  <Helmet title={`${title} - NanoPlanner`} />
                  <Suspense fallback={<PageLoading />}>
                    <Page />
                  </Suspense>
                </Template>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
