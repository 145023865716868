import { useCallback, useState } from 'react'
import { FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa'
import { changeCurrentEstablishment } from '@/services/establishments'
import * as S from './styles'

const ONE_ESTABLISHMENT = 1

const SwitchEstablishment = ({ establishments, currentEstablishment }) => {
  const [changeEstablishment, setChangeEstablishment] = useState(false)

  const handleEstablishmentChange = useCallback(() => {
    setChangeEstablishment(!changeEstablishment)
  }, [changeEstablishment])

  const handleSetEstablishment = useCallback((establishment) => {
    const callback = () => window.location.reload()
    changeCurrentEstablishment(establishment?.id, establishment, callback)
  }, [])

  if(!establishments || establishments?.length === ONE_ESTABLISHMENT) return null

  return (
    <S.Container>
      <S.Title>Clínica atual</S.Title>

      <S.Establishment onClick={handleEstablishmentChange}>
        <S.EstablishmentName>{currentEstablishment?.name}</S.EstablishmentName>
        {
          changeEstablishment ?
            <FaChevronUp />
            :
            <FaChevronDown />
        }
      </S.Establishment>

      {
        changeEstablishment && (
          <>
            <S.Overlay onClick={handleEstablishmentChange} />
            <S.EstablishmentContent>
              <S.Title>Selecionar clínica</S.Title>

              <S.Establishments>
                {
                  establishments?.map(establishment => (
                    <S.Establishment key={`establishment-${establishment?.id}`} onClick={() => handleSetEstablishment(establishment)}>
                      <S.EstablishmentName>{establishment?.name}</S.EstablishmentName>
                      <FaChevronRight />
                    </S.Establishment>
                  ))
                }
              </S.Establishments>
            </S.EstablishmentContent>
          </>
        )
      }
    </S.Container>
  )
}

export default SwitchEstablishment
