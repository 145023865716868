import * as S from './styles'

const FormContainer = ({ children }) => {
  return (
    <S.Container>
      {children}
    </S.Container>
  )
}

export default FormContainer
