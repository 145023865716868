import { createContext, useCallback, useState } from "react";

export const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState({
    isLoading: false,
    title: '',
    subtitle: ''
  });

  const { isLoading, subtitle, title } = loading;

  const setIsLoading = useCallback((data) => {
    setLoading({
      ...loading,
      ...data
    });
  }, [])

  const closeLoading = useCallback(() => {
    setLoading({
      ...loading,
      isLoading: false,
      title: '',
      subtitle: ''
    });
  }, []);

  return (
    <LoaderContext.Provider value={{ isLoading, subtitle, title, setIsLoading, closeLoading }}>
      {children}
    </LoaderContext.Provider>
  );
}

export default LoaderProvider