import { createContext, useCallback, useState } from "react";

export const AuthDocContext = createContext();

const AuthDocProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [secretCode, setSecretCode] = useState("");

  const setAuthentication = useCallback((authenticated) => {
    setIsAuthenticated(authenticated);
  }, []);

  return (
    <AuthDocContext.Provider
      value={{ isAuthenticated, secretCode, setSecretCode, setAuthentication }}
    >
      {children}
    </AuthDocContext.Provider>
  );
};

export default AuthDocProvider;
