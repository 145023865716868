import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.dark};
    width: 25rem;
    height: calc(100vh);
    border-right: 0.1rem solid ${theme.colors.disabled};

    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  `}
`
export const Wrapper = styled.nav`
  padding: 0 2rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const Icon = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 0.4rem;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.4rem;
    height: 2.4rem;
    color: ${theme.colors.primary.default};
  `}
`

export const Item = styled.span`
  ${({ theme, active }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: ${theme.colors.gray400};
    font-weight: 600;
    gap: 0.8rem;
    padding: 1.2rem 1.6rem;
    transition: 0.3s;
    border-radius: 1.5rem;

    ${active && css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.dark};
      
      ${Icon} {
        background-color: ${theme.colors.primary.default};
        color: ${theme.colors.white};
      }
    `}
    
    &:hover {
      opacity: 0.7;
    }
    
    &:active {
      opacity: 0.3;
    }
  `}
`



export const Logout = styled.div`
  margin-top: auto;
  margin-bottom: 1.6rem;
  padding: 0 2rem;
`

export const Header = styled.div`
  padding: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`

export const Logo = styled.img.attrs({ src: '/images/logo.svg'})``