import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.colors.white};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: translate3d(0px, 0px, 0px) translateY(-10%) !important;
    }
  `};
`

const pulseLoding = css`
  @keyframes pulseLoding {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
`
export const Title = styled.h1`
    font-size: 2rem;
    font-weight: 400;
`

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 300;
`

export const Hgroup = styled.hgroup`
  ${({ theme }) => css`
    position: relative;
    z-index: 3;
    color: ${theme.colors.gray700};
    animation: pulseLoding 1s infinite;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${pulseLoding}
  `};
`

export const LottieContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
`