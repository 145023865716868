import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: calc(100% - 29rem);
  margin-left: 27rem;
`;
