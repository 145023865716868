import Cookies from "js-cookie";
import { httpService } from "@/services/api";
import { removeFormatting } from "@/utils";
import {
  changeCurrentEstablishment,
  getCurrentEstablishmentInfo,
} from "../establishments";
import Swal from "sweetalert2";

export const EMPTY_USER = {
  personalName: null,
  isLoggedIn: false,
  establishment: [],
};

export const setUser = (user) => {
  localStorage.setItem(
    process.env.REACT_APP_CURRENT_USER,
    JSON.stringify(user)
  );
};

export const getUser = () => {
  if (!localStorage.getItem(process.env.REACT_APP_CURRENT_USER)) {
    return EMPTY_USER;
  }

  const user = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_CURRENT_USER)
  );
  const currentEstablishment = getCurrentEstablishmentInfo();

  return {
    ...user,
    currentEstablishment,
  };
};

export const setToken = (token) => {
  Cookies.set(process.env.REACT_APP_AUTH_TOKEN, token);
};

export const isAuthenticated = () => {
  const token = Cookies.get(process.env.REACT_APP_AUTH_TOKEN);
  return token ? true : false;
};

export const logIn = async ({ identifier, password, type }) => {
  const { data } = await httpService.post(
    "/auth",
    {
      identifier: removeFormatting(identifier),
      password,
    },
    {
      headers: {
        "user-type": type,
      },
    }
  );
  const { user, jwt, isDigitalSignature } = data;

  const { establishment } = user;
  const [currentEstablishment] = establishment;

  await changeCurrentEstablishment(
    currentEstablishment.id,
    currentEstablishment
  );
  setToken(jwt);

  const info = {
    ...user,
    isLoggedIn: true,
    currentEstablishment,
    isDigitalSignature,
  };

  setUser(info);

  const lastPage = localStorage.getItem(process.env.REACT_APP_LAST_PAGE);

  if (lastPage) {
    const { isConfirmed } = await Swal.fire({
      title: "Deseja voltar para a última página?",
      showDenyButton: true,
      confirmButtonText: `Sim`,
      denyButtonText: `Não`,
    });

    if (isConfirmed) {
      window.location.href = lastPage;
    }

    localStorage.removeItem(
      process.env.REACT_APP_LAST_PAGE,
      window.location.pathname
    );
  }

  return info;
};

export const forgotPassword = async ({ identifier }) => {
  await httpService.post("/auth/password/reset", {
    identifier: removeFormatting(identifier),
  });
};

export const resetPassword = async ({
  code,
  password,
  passwordConfirmation,
}) => {
  const { data } = await httpService.put("/auth/password/reset", {
    code,
    password,
    passwordConfirmation,
  });

  return data;
};

export const logOut = () => {
  Cookies.remove(process.env.REACT_APP_AUTH_TOKEN);
  localStorage.removeItem(process.env.REACT_APP_CURRENT_USER);
  localStorage.removeItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT);
  localStorage.removeItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT_INFO);
};

export const setLastPage = (page) => {
  localStorage.setItem(process.env.REACT_APP_LAST_PAGE, page);
};
