import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    html {
      font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
    }

    body,
    html {
      font-family: "Roboto", sans-serif;
      color: ${theme.colors.black};
      background-color: ${theme.colors.gray50};
    }

    .hide {
      display: none !important;
    }

    .scrollbar {
      /* Personaliza o scrollbar inteiro (vertical e horizontal) */
      &::-webkit-scrollbar {
        width: 0.5rem; /* Largura do scrollbar vertical */
        height: 12px; /* Altura do scrollbar horizontal */
      }

      /* Personaliza o fundo da trilha (parte onde o scrollbar se move) */
      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Cor de fundo da trilha */
      }

      /* Personaliza a alça do scrollbar (a parte que você arrasta) */
      &::-webkit-scrollbar-thumb {
        background: #888; /* Cor da alça do scrollbar */
      }

      /* Altera a cor da alça do scrollbar ao passar o mouse */
      &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Cor da alça do scrollbar ao passar o mouse */
      }
    }
  `}
`;

export default GlobalStyle;
