import { WAIT_TIME } from "@/config/constants";

export function debounce(func, wait = WAIT_TIME) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}


export const transformNullValuesToEmptyStringRecursive = (obj) => {
  if (obj === null) {
    return '';
  }

  if (typeof obj === 'object') {
    const result = {};

    Object.keys(obj).forEach((key) => {
      result[key] = transformNullValuesToEmptyStringRecursive(obj[key]);
    });

    return result;
  }

  return obj;
}

export const deleteNullValuesRecursive = (obj) => {
  if (obj === null) {
    return null;
  }

  if (typeof obj === 'object') {
    const result = {};

    Object.keys(obj).forEach((key) => {
      const value = deleteNullValuesRecursive(obj[key]);
      if (value !== null) {
        result[key] = value;
      }
    });

    return result;
  }

  return obj;
}

export const deleteEmptyStringsRecursive = (obj) => {
  if (obj === '') {
    return null;
  }

  if (typeof obj === 'object') {
    const result = {};

    Object.keys(obj).forEach((key) => {
      const value = deleteEmptyStringsRecursive(obj[key]);
      if (value !== '') {
        result[key] = value;
      }
    });

    return result;
  }

  return obj;
}

export const transformToArray = (answers) => {
  const array = [];
  for (let key in answers) {
      array.push(answers[key]);
  }
  return array;
}

export const removeFormatting = (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/\D/g, '');
}