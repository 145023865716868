import { Link as RouterLink} from 'react-router-dom'
import * as S from './styles'

const Link = ({ to, action, children, external = false, ...props }) => {
  
  if(action) {
    return (
      <S.Container onClick={action} {...props}>
        {children}
      </S.Container>
    )
  }
  
  return (
    <S.Container {...props}>

      {
        external && (
          <S.Link href={to} target='_blank' rel="noreferrer" {...props}>{children}</S.Link>
        )
      }

      {
        !external && (
          <RouterLink to={to} {...props}>{children}</RouterLink>
        )
      }
    </S.Container>
  )
}

export default Link
