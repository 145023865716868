import menu, { PATH_HOME } from '@/config/menu'
import * as S from './styles'
import useAuth from '@/hooks/useAuth'
import { FaSignOutAlt } from 'react-icons/fa'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useCallback } from 'react'
import SwitchEstablishment from '../SwitchEstablishment'

const Menu = ({ establishment, currentEstablishment }) => {
  const location = useLocation()
  
  const { handleLogout } = useAuth()
  const navigate = useNavigate()

  const handleGoToHome = () => {
    navigate('/dashboard')
  }

  const isActive = useCallback((itemHref) => {
    const isNotHome = location.pathname !== PATH_HOME;
    
    if(isNotHome && itemHref === PATH_HOME) {
      return false
    }

    return location.pathname.includes(itemHref)
  }, [location])

  return (
    <S.Container>
      <SwitchEstablishment establishments={establishment} currentEstablishment={currentEstablishment} />
      <S.Header onClick={handleGoToHome}>
        <S.Logo />
      </S.Header>
      <S.Wrapper>
        {menu.map(item => (
          <Link key={`menu-${item.id}`} to={item.href}>
            <S.Item active={isActive(item.href)}>
              <S.Icon>{item.icon}</S.Icon>
              {item.title}
            </S.Item>
          </Link>
        ))}
      </S.Wrapper>

      <S.Logout>
        <S.Item onClick={handleLogout}>
        <S.Icon><FaSignOutAlt /></S.Icon>
          Sair
        </S.Item>
      </S.Logout>
    </S.Container>
  )
}

export default Menu
