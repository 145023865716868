import { FaBell, FaCog, FaUser } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'

import menu, { PATH_HOME } from '@/config/menu'
import Link from '@/components/Link'

import * as S from './styles'

const isActive = (item, location) => {
  const isNotHome = location.pathname !== PATH_HOME;
  
  if(isNotHome && item.href === PATH_HOME) {
    return false
  }

  if(item.isPathParam) {
    const [firstPath, secondPath] = item.href.split('/')
    const [firstLocation, secondLocation] = location.pathname.split('/')

    return firstPath === firstLocation && secondPath === secondLocation;
  }

  return location.pathname.includes(item.href)
}

const Header = ({ personalName }) => {
  const location = useLocation()

  const activeMenu = menu.find(item => isActive(item, location))
  const activeSubMenuItem = activeMenu?.submenu?.find(item => isActive(item.href, location))

  return (
    <S.Container>
      <S.Left>
        <S.BreadCrumb>
          <p>{activeMenu?.title}</p> <p>{activeSubMenuItem?.title &&  `> ${activeSubMenuItem?.title}`}</p>
        </S.BreadCrumb>
      </S.Left>

      <S.Right>
        <S.User>
          <S.Avatar>
            <FaUser  size={12}/>
          </S.Avatar>
          <S.Name>{personalName}</S.Name>
        </S.User>

        <S.Actions>
          <Link to='/dashboard/configuracoes/meu-cadastro'>
            <FaCog size={14}/>
          </Link>
          <FaBell size={14}/>
        </S.Actions>
      </S.Right>
    </S.Container>
  )
}

export default Header
