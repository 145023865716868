import { httpService } from '@/services/api';

export const detail = async () => {
  const { data } = await httpService.get('/establishments');

  return data
}

export const update = async (payload) => {
  delete payload.id
  delete payload.createdAt
  delete payload.establishment
  delete payload.publishedAt
  delete payload.updatedAt

  const { data } = await httpService.put('/establishments', {
    ...payload
  });
  return data
}

export const changeCurrentEstablishment = async (id, info, callback) => {
  localStorage.setItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT, id)
  localStorage.setItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT_INFO, JSON.stringify(info))
  callback && callback()
}

export const getCurrentEstablishment = () => {
  return localStorage.getItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT)
}

export const getCurrentEstablishmentInfo = () => {
  const currentEstablishment = localStorage.getItem(process.env.REACT_APP_CURRENT_ESTABLISHMENT_INFO)
  if (!currentEstablishment || currentEstablishment === 'undefined') {
    return null
  }

  return JSON.parse(currentEstablishment)
}
