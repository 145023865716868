export const ROLE_KEYS = {
  DOCTOR: 'DOCTOR',
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
}

export const ROLE = {
  DOCTOR: 3,
  CUSTOMER: 4,
  ADMIN: 5,
}