import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem;
  width: calc(100% - 25rem);
  margin-right: 0;
  margin-left: auto;
`

export const Left = styled.div``
export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const Avatar = styled.div``
export const Name = styled.p`
  font-weight: 700;
  font-size: 1.4rem;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  * {
    cursor: pointer;
  }
`

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.4rem;

  font-weight: 700;
  font-size: 1.6rem;
`