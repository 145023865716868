export const WHATSAPP_WEB = (phone, text) =>
  `https://web.whatsapp.com/send/?phone=55${phone}&text=${text}&type=phone_number&app_absent=0`;
export const WAIT_TIME = 500;

export const OPERATORS = [
  { label: "É igual a", value: "$eqi", showsTo: ["string"] },
  {
    label: "Não é igual a",
    value: "$nei",
    showsTo: ["string"],
  },
  { label: "É menor que", value: "$lt", showsTo: ["number", "date"] },
  { label: "É menor ou igual a", value: "$lte", showsTo: ["number", "date"] },
  { label: "É maior que", value: "$gt", showsTo: ["number", "date"] },
  { label: "É maior ou igual a", value: "$gte", showsTo: ["number", "date"] },
  { label: "Está incluído em", value: "$in", showsTo: ["string"] },
  { label: "Não está incluído em", value: "$notIn", showsTo: ["string"] },
  {
    label: "Contém",
    value: "$containsi",
    showsTo: ["string"],
  },
  {
    label: "Não contém",
    value: "$notContainsi",
    showsTo: ["string"],
  },
  { label: "É nulo", value: "$null", showsTo: ["string", "date", "boolean", "number"] },
  { label: "Não é nulo", value: "$notNull", showsTo: ["string", "number", "date", "boolean"] },
  { label: "Está entre", value: "$between", showsTo: ["number", "date"] },
  {
    label: "Começa com",
    value: "$startsWithi",
    showsTo: ["string"],
  },
  {
    label: "Termina com",
    value: "$endsWithi",
    showsTo: ["string"],
  },
  { label: "Não", value: "$not", showsTo: ["string", "number", "date", "boolean"] },
];
