import Lottie from "lottie-react";
import * as S from './styles'

import wavesAnimation from "./waves.json";
import { LoaderContext } from "@/providers/LoaderProvider";
import { useContext } from "react";

const defaultProps = {
  overrideIsLoading: false,
  overrideTitle: 'Carregando',
  overrideSubtitle: 'Isso pode levar alguns segundos'
}

const LoadingContent = ({ title, subtitle, isLoading }) => {
  if(!isLoading) {
    return <></>
  }

  return (
    <S.Container>
      <S.Hgroup>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.Hgroup>

      <S.LottieContainer>
        <Lottie animationData={wavesAnimation} loop={true} />
      </S.LottieContainer>
    </S.Container>
  )
}

const PageLoading = ({ overrideIsLoading,  overrideTitle, overrideSubtitle }) => {
  const { isLoading, title, subtitle } = useContext(LoaderContext);

  if(overrideIsLoading) {
    return (
      <LoadingContent
        title={overrideTitle}
        subtitle={overrideSubtitle}
        isLoading={overrideIsLoading}
      />
    )
  }

  return (
    <LoadingContent
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
    />
  )
}

PageLoading.defaultProps = defaultProps

export default PageLoading
